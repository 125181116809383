@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
p,
a,
li {
  font-family: "Inter", sans-serif;

  font-family: "Lexend Deca", sans-serif;
}

.coomer-breathe h2 {
  animation: breathe 2s infinite alternate;
}

.me {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes breathe {
  from {
    font-size: 1.3rem;
  }
  to {
    font-size: 3rem;
  }
}
